import React, { useEffect, useState, useCallback, useRef } from 'react';
import './hexagon.css';

const debounce = (func: Function, delay: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
};

const HexagonGrid = () => {
    const [hexagonDataInitialized, setHexagonDataInitialized] = useState(false);
    const [numHexagons, setNumHexagons] = useState<number>(Math.floor((window.innerHeight * window.innerWidth) / (100 * 86.59) * 4));
    
    const touchStartRef = useRef<{ x: number; y: number } | null>(null);

    const calculateHexagonNum = () => {
        if (window.innerWidth > 1440) {
            setNumHexagons(Math.ceil((window.innerHeight + 100) / 86.59) * 40);
            
        } else if (window.innerWidth > 768) {
            setNumHexagons(Math.ceil((window.innerHeight + 100) / 86.59) * 22);
        } else if (window.innerWidth > 480) {
            setNumHexagons(Math.ceil((window.innerHeight + 100) / 86.59) * 16);
        } else {
            setNumHexagons(Math.ceil((window.innerHeight + 400) / 86.59) * 12);
        }
    };

    const initializeHexagonData = useCallback(() => {
        setHexagonDataInitialized(true);
    }, []);

    const debouncedInitializeHexagonData = debounce(initializeHexagonData, 300);

    useEffect(() => {
        const handleResize = () => {
            calculateHexagonNum();
            debouncedInitializeHexagonData();
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial call

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [debouncedInitializeHexagonData]);

    

    

   

    const handleTouchStart = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        touchStartRef.current = { x: touch.clientX, y: touch.clientY };
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        if (touchStartRef.current) {
            const startX = touchStartRef.current.x;
            const startY = touchStartRef.current.y;
            const endX = touch.clientX;
            const endY = touch.clientY;
            const dx = endX - startX;
            const dy = endY - startY;

            if (Math.abs(dx) > 30 || Math.abs(dy) > 30) {
                // Handle swipe event

                touchStartRef.current = null; // Reset after swipe
            }
        }
    };

    const handleTouchEnd = () => {
        touchStartRef.current = null;
    };

    return (
        <div
            className="hexagon-grid"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {Array.from({ length: numHexagons }).map((_, index) => (
                <div
                    key={index}
                    className={`hexagon`}
                />
            ))}
        </div>
    );
};

export default HexagonGrid;
