import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface RoleContextProps {
    role: string;
    setRole: (role: string) => void;
}

const RoleContext = createContext<RoleContextProps | undefined>(undefined);

export const RoleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [role, setRole] = useState(() => {
        const savedRole = localStorage.getItem('role');
        return savedRole ? savedRole : 'Webmaster';
    });

    useEffect(() => {
        localStorage.setItem('role', role);
    }, [role]);

    return (
        <RoleContext.Provider value={{ role, setRole }}>
            {children}
        </RoleContext.Provider>
    );
};

export const useRole = () => {
    const context = useContext(RoleContext);
    if (!context) {
        throw new Error('useRole must be used within a RoleProvider');
    }
    return context;
};
