import i18n from "i18next";                      
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";


export const supportedLngs = {
       en: "English",
       ru: "Русский",
    };

i18n
  // Add React bindings as a plugin.
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    // Initialize the i18next instance.
    .init({
        fallbackLng: "en",
        supportedLngs: Object.keys(supportedLngs),
        debug: true,
        interpolation: {
        escapeValue: false,
        },
    });

export default i18n;