import React from "react";
import "./roadmap.css";

type Props = { h1: string, main_text : string,  main_text2 : string};
export const AboutComponentDesktop: React.FC<Props> = (props) => {
  return (
    <div className="AboutBlock">
      <div className="AboutContent">
        <div className="AboutText">
        <h1 style={{ marginBottom: '45px' }} dangerouslySetInnerHTML={{ __html: props.h1 }}></h1>
         
          <p style={{ marginBottom: '15px' }}className="ipadtext"  dangerouslySetInnerHTML={{ __html: props.main_text }} />
          <p style={{ marginTop: '5px' }}className="ipadtext" dangerouslySetInnerHTML={{ __html: props.main_text2 }} />
        </div>
        <div className="ImgContent">
          <img style={{ zIndex:1 }}src="BeeMagnifier.png" alt="Bee" />
          <img style={{ position: 'fixed', marginTop: '50px', marginLeft:'50px', height:'450px', zIndex:0 }}src="desktopline.png"/>
        </div>
      </div>
    </div>
  );
};

// Компонент для мобильных устройств
export const AboutComponentMobile: React.FC<Props> = (props) => {
  return (
    <div className="AboutBlock">
      <div className="AboutContent">
        <div className="AboutText">
          <h1 dangerouslySetInnerHTML={{ __html: props.h1 }}></h1>
          <div className="ImgContent">
          <img src="BeeMagnifier.png" alt="Bee" />
          <img className='linefirsthalf1' src="linefirsthalf1.png"/>
          <img className='linefirsthalf2' src="linefirsthalf2.png"/>
          </div>
          

          <div className="TextWithImage">
            <div className="Imgwithtext">
              <img src="icon copy.png" alt="Image 1" />
            </div>
            <p className="Textp" dangerouslySetInnerHTML={{ __html: props.main_text }} />
          </div>

          <div className="TextWithImage">
            <div className="Imgwithtext">
              <img src="icon copy.png" alt="Image 2" />
            </div>
            <p className="Textp" dangerouslySetInnerHTML={{ __html: props.main_text2 }} />
          </div>
        </div>
      </div>
    </div>
  );
};
