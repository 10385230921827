import React from 'react';
import './footer.css';
import imagePath from '../Header/png2.svg';

export const Footer: React.FC = () => {
    return (
        <div className="footer-container">
            <footer className="footer">
                <p>AdNetwork Honey — a step forward</p>
                <img src={imagePath} className='footer-figure-img'/>
                <a href='https://t.me/AdNetworkHoney'>Telegram ENG</a>
                <a href='https://t.me/AdNetworkHoneyRU'>Telegram RU</a>
            </footer>
            
        </div>
    );
};
