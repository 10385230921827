import { useTranslation } from "react-i18next";
import { supportedLngs } from "./config";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme } from '@mui/material/styles';

import './localeswitcher.css';

export default function LocaleSwitcher() {
    const { i18n } = useTranslation();
    

    const handleLocaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(i18n.resolvedLanguage == "en") {
            i18n.changeLanguage("ru");
        }
        else {
            i18n.changeLanguage("en");
        }
    };

    return (
        <div className="locale-switch">
            <FormControlLabel
                control={
                    <Switch
                        checked={i18n.resolvedLanguage === 'en'}
                        onChange={handleLocaleChange}
                        name="localeSwitch"
                        color="primary"
                    />
                }
                label={<span className="locale-label">{i18n.resolvedLanguage}</span>}
            />
        </div>
      );

}