import React from "react";
import "./roadmap.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

type Props = { text: string[]; head: string; inwork: boolean };

const TextComponent: React.FC<Props> = (props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.75,
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      className={`content ${inView ? "in-view" : ""} ${props.inwork ? "blinking-shadow" : ""}`}
    >
      <h1 className="quar">{props.head}</h1>
      <div className="content-text">
        <div className="roadmap-grid-text">
          {props.text.map((item, index) => (
            <div key={index} className="roadmap-item">
              <p dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default TextComponent;
