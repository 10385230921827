import { Link } from 'react-router-dom';
import LocaleSwitcher from "../../i18n/LocaleSwitcher"

const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
        const headerOffset = 100; // Adjust this value to the height of your header or the desired offset
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition - 50,
            behavior: 'smooth'
        });
    }
};

export const Menu = () => {
    return(
        <ul>
            <li>
                <Link onClick={() => scrollToSection('Home')} to="/">Home</Link>
            </li>
            <li>
                <a onClick={() => scrollToSection('Roadmap')}>Roadmap</a>
            </li>
            <li>
                <a onClick={() => scrollToSection('InfoAbout')}>Info</a>
            </li>
            <li>
                <a onClick={() => scrollToSection('Ambitions')}>Ambitions</a>
            </li>
            
            <LocaleSwitcher/>
        </ul>
    );
}