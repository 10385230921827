import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import './header.css';
import { Link } from 'react-router-dom';
import { useRole } from '../../rolecontext';
import imagePath from './png2.svg';
import { Menu } from './Menu';

export const Header: React.FC = () => {
    const { role, setRole } = useRole();

    const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
    const [menu_class, setMenuClass] = useState("menu hidden");
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    // toggle burger menu change
    const updateMenu = () => {

        if(!isMenuClicked) {
            setBurgerClass("burger-bar clicked");
            setMenuClass("menu visible");
        }
        else {
            setBurgerClass("burger-bar unclicked");
            setMenuClass("menu hidden");
        }
        setIsMenuClicked(!isMenuClicked)
    }
      

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setBurgerClass("burger-bar unclicked");
                setMenuClass("menu hidden");
                setIsMenuClicked(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    


    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            const headerOffset = 100; // Adjust this value to the height of your header or the desired offset
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(event.target.checked ? 'Advertiser' : 'Webmaster');
    };

    const handleDashboardClick = () => {
        if (role === 'Advertiser') {
            window.location.href = '/dashboardAdv';
        } else {
            window.location.href = '/dashboardWM';
        }
    };

    return (
        <div className="Headers-div">
            <header className={menu_class}>
                <div className="nav-container">
                    <img src={imagePath} className="figure-img"/>
                    <h1 className='slogan'>AdNetwork Honey -<br/> a step forward</h1>
                    <nav className="nav-desctop">
                        <Menu/>
                    </nav>

                    <div className="TonConnectButton">
                        <div className="nav-mobile">
                            <div className="burger-menu" onClick={updateMenu}>
                                <div className={burger_class} ></div>
                                <div className={burger_class} ></div>
                                <div className={burger_class} ></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='Dropdown-menu'>
                    <Menu/>
                </div>
            </header>
        </div>
    );
};
