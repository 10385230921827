import "./App.css";
import { Header } from "./components/Header/Header";

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RoleProvider, useRole } from "./rolecontext";
import { useMediaQuery } from "react-responsive";

import TextComponent from "./components/Home/TextBlock";
import {
  AboutComponentDesktop,
  AboutComponentMobile,
} from "./components/Home/AboutBlock";
import {
  InfoComponentdesktopconcept,
  InfoComponentdesktopmotivation,
  InfoComponentdesktopplans,
  InfoComponentmobile,
} from "./components/Home/InfoBlock";
import HexagonGrid from "./hexagon-grid";
import { Footer } from "./components/footer/footer";

import { useTranslation } from "react-i18next";

function App() {
  return (
    <Router>
      <RoleProvider>
        <AppContent />
      </RoleProvider>
    </Router>
  );
}

const AppContent: React.FC = () => {
  return (
    <div className="app-container">
      <Header />
      <div id="content">
        <div className="hexagon-background">
          <HexagonGrid />
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className="Ton">
      <div className="content-blur">
        <section
          
          id="Home"
          className="section"
        >
          {isMobile ? (
            <section style={{ marginBottom: "0px" }}>
            <AboutComponentMobile
              h1={t("MainText.h1")}
              main_text={t("MainText.main")}
              main_text2={t("MainText.main2")}
            />
            </section>
          ) : (
            <section style={{ marginBottom: "150px" }}>
            <AboutComponentDesktop
              h1={t("MainText.h1")}
              main_text={t("MainText.main")}
              main_text2={t("MainText.main2")}
            />
            </section>
          )}
        </section>
        <section style={{ marginBottom: '10px' }} id="Roadmap" className="section">
          <TextComponent
            text={t("text1", { returnObjects: true })}
            head="Q3 2024"
            inwork={false}
          />
        </section>
        <section style={{ marginBottom: '10px' }} className="section">
          <TextComponent
            text={t("text2", { returnObjects: true })}
            head="Q4 2024"
            inwork={false}
          />
        </section>
        <section style={{ marginBottom: '10px' }} className="section">
          <TextComponent
            text={t("text3", { returnObjects: true })}
            head="Q1 2025"
            inwork={false}
          />
        </section>
        <section style={{ marginBottom: "150px" }} className="section">
          <TextComponent
            text={t("text4", { returnObjects: true })}
            head="Q2 2025"
            inwork={false}
          />
        </section>
        <section id="InfoAbout">
          {isMobile ? (
            <InfoComponentmobile
              h1Concept={t("ConceptText.h1")}
              textConcept={t("ConceptText.main")}
              h1Plans={t("PlansText.h1")}
              textPlans={t("PlansText.main")}
              h1Motivation={t("MotivationText.h1")}
              textMotivation={t("MotivationText.main")}
            />
          ) : (
            <section style={{ marginBottom: "250px" }}>
              <InfoComponentdesktopconcept
                h1Concept={t("ConceptText.h1")}
                textConcept={t("ConceptText.main")}
                h1Plans={t("PlansText.h1")}
                textPlans={t("PlansText.main")}
                h1Motivation={t("MotivationText.h1")}
                textMotivation={t("MotivationText.main")}
              />
              <img
                className="line-img"
                
                src="Group 52 (1).svg"
              ></img>
              <InfoComponentdesktopplans
                h1Concept={t("ConceptText.h1")}
                textConcept={t("ConceptText.main")}
                h1Plans={t("PlansText.h1")}
                textPlans={t("PlansText.main")}
                h1Motivation={t("MotivationText.h1")}
                textMotivation={t("MotivationText.main")}
              />

              <InfoComponentdesktopmotivation
                h1Concept={t("ConceptText.h1")}
                textConcept={t("ConceptText.main")}
                h1Plans={t("PlansText.h1")}
                textPlans={t("PlansText.main")}
                h1Motivation={t("MotivationText.h1")}
                textMotivation={t("MotivationText.main")}
              />
            </section>
          )}
        </section>
        <section id="Ambitions" className="section">
          <TextComponent
            text={t("text5", { returnObjects: true })}
            head="Ambitions"
            inwork={true}
          />
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default App;
