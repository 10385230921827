import React from "react";
import "./roadmap.css";

import { useMediaQuery } from "react-responsive";

type Props = {
  h1Plans: string;
  textPlans: string;
  h1Concept: string;
  textConcept: string;
  h1Motivation: string;
  textMotivation: string;
};
export const InfoComponentdesktopconcept: React.FC<Props> = (props) => {
  return (
    
    <div className="AboutBlock">
      <div className="AboutContent">
      <div className="AboutText">
          <h1 style={{ marginBottom: '15px' }}>{props.h1Concept}</h1>
          <div className="TextWithline">
          <p dangerouslySetInnerHTML={{ __html: props.textConcept }} />
            </div>
        </div>
        <div className="ImgContent">
          <img style={{ transform: 'rotate(320deg)' }} src="beebring.png" alt="Bee" />
        </div>
      </div>
    </div>
  );
};

export const InfoComponentdesktopplans: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1120px)" });
    return (
      
      <div style={{ transform: 'scaleX(-1)' }} className="AboutBlock">
        <div className="AboutContent">
        <div style={{ transform: 'scaleX(-1)' }}className="AboutText">
            <h1 style={{ marginBottom: '15px' }}>{props.h1Plans}</h1>
            <div className={isMobile ? "TextWithline" : "TextWithlineRight"}>
            <p dangerouslySetInnerHTML={{ __html: props.textPlans }} />
          </div>
          </div>
          <div className="ImgContent">
            <img style={{ transform: 'scaleX(-1) rotate(20deg)' }}src="beebringnewspaper.png" alt="Bee" />
            
          </div>
        </div>
      </div>
    );
  };

  export const InfoComponentdesktopmotivation: React.FC<Props> = (props) => {
    return (
      
      <div className="AboutBlock">
        <div className="AboutContent">
        <div className="AboutText">
            <h1 style={{ marginBottom: '15px' }}>{props.h1Motivation}</h1>
            <div className="TextWithline">
            <p dangerouslySetInnerHTML={{ __html: props.textMotivation }} />
            </div>
          </div>
          <div className="ImgContent">
            <img src="BeeNews.png" alt="Bee" />
          </div>
        </div>
      </div>
    );
  };

export const InfoComponentmobile: React.FC<Props> = (props) => {
  return (
    <div className="AboutBlock">
      <div className="AboutContent">
        <div className="AboutText">
          <h1 style={{ textAlign: 'left', marginBottom: '80px'}}>{props.h1Concept}</h1>
          <div className="TextWithline">
            <p
              className="TextPline"
              dangerouslySetInnerHTML={{ __html: props.textConcept }}
            />
          </div>
        </div>
        <div className="ImgContent">
          <img src="beebring.png" />
        </div>
        <div className="AboutText">
          <h1 style={{ textAlign: 'left', marginBottom: '80px' }}>{props.h1Plans}</h1>
          <div className="TextWithline">
            <p
              className="TextPline"
              dangerouslySetInnerHTML={{ __html: props.textPlans }}
            />
          </div>
        </div>
        <div className="ImgContent">
          <img src="beebringnewspaper.png" />
        </div>
        <div className="AboutText">
          <h1 style={{ textAlign: 'left', marginBottom: '80px' }}>{props.h1Motivation}</h1>
          <div className="TextWithline">
            <p
              className="TextPline"
              dangerouslySetInnerHTML={{ __html: props.textMotivation }}
            />
          </div>
        </div>
        
        <div className="ImgContent">
          <img src="BeeNews.png" />
        </div>
      </div>
    </div>
  );
};
